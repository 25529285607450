import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from "../../components/sectiontitle.jsx";
import Section from "../../components/section.jsx";

import "react-medium-image-zoom/dist/styles.css";

function PortfolioFoster() {
  return (
    <Layout
      currentPage={[
        { title: `Portfolio`, url: `portfolio` },
        { title: `Foster`, url: `/portfolio/foster` },
      ]}
    >
      <SEO
        keywords={[
          `Robert Merki`,
          `Robmerki`,
          `Rob Merki`,
          `@robmerki`,
          `Merki.ca`,
        ]}
        title="Foster"
      />

      <Section>
        <SectionTitle title="Foster" />
        <p>
          Foster is a marketplace for writers to find on-demand editorial
          support for their Google Drafts.
        </p>
        <p>
          I worked there as a Computer Systems Analyst at Foster from
          winter 2021 to early summer 2023. Primarily, I help the engineering team with the
          development of the Foster Chrome Extension & connecting all the
          relevant systems into the Google Docs interface.
        </p>
      </Section>
    </Layout>
  );
}

export default PortfolioFoster;
